
// vue
import { computed, inject, reactive, ref, watch } from 'vue';

// icons
import { home, people, calendar, newspaper, compass, personCircle, globeOutline, construct, notifications, scan,
        chevronBack, chevronForward, trashOutline, close, eye, eyeOff, camera, checkmark,
        ellipsisHorizontal, ellipsisVertical, downloadOutline, search, imagesOutline, text,
        qrCodeOutline, arrowBack, statsChart, cloudOfflineOutline, videocamOutline,
        batteryCharging, batteryDead, batteryHalf, batteryFull, locationOutline, homeOutline,
        colorWandOutline, link, unlink, alertCircleOutline, informationCircleOutline, barbellOutline,
        statsChartOutline, analytics, thermometerOutline, waterOutline, cloudyOutline, timeOutline, colorPaletteOutline,
        add, mapOutline, pencil, cloudUploadOutline, logInOutline, buildOutline, bluetooth, } from 'ionicons/icons';

// components
import { IonPage, IonSplitPane, IonMenu, IonMenuButton, IonMenuToggle, IonHeader, IonToolbar, IonBackButton, IonTitle, IonContent, IonFooter,
        IonSegment, IonSegmentButton, IonButtons, IonButton, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonFabButton, IonProgressBar,
        IonModal, IonPopover, IonList, IonItem, IonSpinner, IonInput, IonSelect, IonSelectOption, IonSearchbar, IonToggle, IonAvatar,
        IonAccordionGroup, IonAccordion, IonThumbnail, IonImg, IonFab, IonLabel, IonIcon, IonBadge, IonChip, IonRadioGroup, IonRadio,
        onIonViewDidEnter, onIonViewWillLeave, loadingController, alertController, modalController, } from '@ionic/vue';
import ImageModal from '@/components/modals/ImageModal.vue';
import ImageSlides from "@/components/ImageSlides.vue";
import WidgetTextValue from "@/components/dashboards/WidgetTextValue.vue";
import DashboardPane from "@/components/dashboards/DashboardPane.vue";
import AssetPartPane from "@/components/dashboards/AssetPartPane.vue";
import ScaffoldReportModal from '@/components/dashboards/ScaffoldReportModal.vue';
import ParticlesAnimation from '@/components/dashboards/ParticlesAnimation.vue';
import { SwiperSlide } from 'swiper/vue/swiper-vue';
import BLEScanModal from '@/components/modals/BLEScanModal.vue';

// composables
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import { utilsDevice } from '@/composables/utilsDevice';
import { Photo, usePhotoGallery } from '@/composables/usePhotoGallery';
import { useQRCodeScanner } from '@/composables/useQRCodeScanner';

// Libraries / Capacitor
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';

// Service
import ProjectService from '@/services/ProjectService';
import UserService from '@/services/UserService';
import AssetService from '@/services/AssetService';
import DeviceService from '@/services/DeviceService';
import WinsService from '@/services/WinsService';

// OpenLayers
import 'ol/ol.css';
import { DragPan, Draw, Modify, PinchZoom, Select, Snap, } from 'ol/interaction.js';
import Projection from 'ol/proj/Projection.js';
import Static from 'ol/source/ImageStatic.js';
import { getCenter, getWidth, getHeight, } from 'ol/extent.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Image as ImageLayer, Vector as VectorLayer} from 'ol/layer.js';
import { Feature, Overlay, View, Map, Collection, } from 'ol';
import { LineString, Point } from 'ol/geom';
import { Circle as CircleStyle, Style, Fill, Stroke, Text, Icon, } from 'ol/style.js';
import { FullScreen, defaults as defaultControls } from 'ol/control.js';

// Supabase
import { SupabaseClient } from '@supabase/supabase-js'
import ReportService from '@/services/ReportService';

//import '@/assets/TaipeiSansTCBeta-Regular-normal.js';

// Uppy (for file upload)
import { DragDrop, Dashboard, ProgressBar, StatusBar, } from '@uppy/vue';
import Uppy from '@uppy/core';
import XHR from '@uppy/xhr-upload';
import Compressor from '@uppy/compressor';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.min.css';
import '@uppy/progress-bar/dist/style.min.css';
import '@uppy/status-bar/dist/style.min.css';

export default {
  name: 'ProjectOLPage',
  components: {
    IonPage, IonSplitPane, IonMenu, IonMenuButton, IonMenuToggle, IonHeader, IonToolbar, IonBackButton, IonTitle, IonContent, IonFooter,
    IonSegment, IonSegmentButton, IonButtons, IonButton, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonFabButton, IonProgressBar,
    IonModal, IonPopover, IonList, IonItem, IonSpinner, IonInput, IonSelect, IonSelectOption, IonSearchbar, IonToggle, IonAvatar,
    IonAccordionGroup, IonAccordion, IonThumbnail, IonImg, IonFab, IonLabel, IonIcon, IonBadge, IonChip, IonRadioGroup, IonRadio,
    ImageSlides, WidgetTextValue, DashboardPane, AssetPartPane, SwiperSlide, ParticlesAnimation,
    DragDrop, Dashboard, ProgressBar, StatusBar,
  },
  setup() {
    // Composables
    const { t } = useI18n();
    const { takePhoto, } = usePhotoGallery();
    const { openModal, getProxyImgLink, addResizeUrlParams, presentPrompt, presentToast, sleep, uniqueId,
            getBase64FromUrl, focusKeywordSearchbar, tStr, getLocalizedStr, getRelativeDate, formatDate, convertKeysToCamelCase,
            getProjectStatusColor, getProjectDisplayProgress, PROJECT_STATUSES, numberWithCommas, formatDateString, getHTMLImg, toNum, openLoginModal,
            isMobileWebApp, isNativeApp, } = utils();
    const { scanningQRCode, startScanQRCode, stopScan, } = useQRCodeScanner();

    const tmpGetPhotoLink = (path, resizeImg = true, resizeImgWidth = 300) => {
      if (path.startsWith('http')) return path;
      const photoLink = `https://www.appsheet.com/template/gettablefileurl?appName=BailyAppCMS-1728870&tableName=project_work_photo_records&fileName=${path}`;
      return resizeImg ? addResizeUrlParams(photoLink, resizeImgWidth, false) : photoLink;
    }
    const route = useRoute();
    const { projectId, workLocationType: preSelectWorkLocationType, workLocationId: preSelectWorkLocationId } = route.params;
    let { workPhotoRecordId: preSelectWorkPhotoRecordId } = route.params;

    // State variables
    const store = useStore();
    const currUser = computed(() => store.state.user);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const project = computed(() => store.getters.getProjectById(projectId));
    const projectRoles = computed(() => store.state.projectRoles);
    const isMenuOpened = ref(false);

    const selectedWorkLocationType = ref(preSelectWorkLocationType || "外牆");
    const workLocations = computed(() => {
      const filteredWLs = project.value.workLocations?.filter(wl => wl['類別'] == selectedWorkLocationType.value) || [];
      return selectedWorkLocationType.value == '內牆' ? filteredWLs.filter(wl => wl['多個樓層?'] != true) : filteredWLs;
    });
    const projectFloors = computed(() => {
      const floors = project.value.workLocations?.filter(fp => fp.projectId == projectId && fp['樓層']).map(fp => fp['樓層']) || [];
      return [...new Set(floors)].sort((a: any, b: any) => {
        if (a == 'R/F') return -1; // put to top
        if (a == 'G/F') return 1; // put to bottom
        return Number(b.split("/")[0]) - Number(a.split("/")[0]);
      });
    });
    const getWorkPhotoRecordById = (id) => (project.value.workPhotoRecords.find(l => l.id == id));
    const getWorkLocationNameById = (id) => ((project.value.workLocations?.find(wl => wl.id == id) || {})['位置'] || "");
    const getWorkPhotoRecordsByType = (type, keyword = null) => {
      const { workPhotoRecords, workLocations } = project.value;
      return (workPhotoRecords || []).filter(l => {
        const checkStr = `${l["照片編號"]} ${getWorkLocationNameById(l['subLocationId'])}`.toLowerCase();
        if (keyword && !checkStr.includes(keyword)) return false;
        const location = workLocations?.find(wl => wl.id == l['勘察位置']); // 勘察位置 of the same type
        return location ? location['類別'] == type : false;
      });
    }

    // Supabase Realtime Subscriptions
    const supabase = inject('supabase') as SupabaseClient;
    let channel;
    const handleChangedBatchUploadJobFile = (payload) => {
      console.log(payload);
      const obj = convertKeysToCamelCase(payload.new);
      const targetObj = canvas.bupUploadedFiles.find(f => f.id == obj.id);
      if (targetObj) {
        for (const key in obj) targetObj[key] = obj[key];
      } else {
        canvas.bupUploadedFiles.unshift(obj);
      }
    }
    const subBatchUploadJobFileTable = (jobId) => {
      if (canvas.bupSupabaseChannel) return; // already subscribed
      const channel = supabase.channel('bup-files');
      channel.on('postgres_changes', { event: '*', schema: 'public', table: 'batch_upload_job_files', filter: `job_id=eq.${jobId}` }, handleChangedBatchUploadJobFile);
      channel.subscribe(async (status) => {
        console.log(status);
        if (status !== 'SUBSCRIBED') { return }
      });
      canvas.bupSupabaseChannel = channel;
    }

    // Uppy
    let token = null;
    const uppy = new Uppy({
      id: `batch-upload-photos`,
      restrictions: { allowedFileTypes: ['image/*'] },
      allowMultipleUploadBatches: false,
      onBeforeUpload: (files) => {
        if (canvas.bupRecognitionMode == 'manual' && canvas.bupPrefixLocationMappings.some(m => m.workLocationId == null)) {
          uppy.info(tStr('請選擇所有相片編號開首的對應位置', 'Please select corresponding locations of all photo code prefixes'), 'error', 5000);
          return false;
        }
        return true;
      },
    })
    .use(Compressor, { maxWidth: 2000, quality: 0.6 })
    .use(XHR, {
      shouldRetry: (xhr) => (true),
      timeout: 30 * 1000,
      limit: 200, // batch token: max 200 requests / second
      endpoint: 'https://batch.imagedelivery.net/images/v1',
      allowedMetaFields: [],
      async onBeforeRequest(xhr) {
        //xhr.timeout = 10000; // timeout in 10 seconds
        if (!token) token = await WinsService.fetchImageUploadBatchToken();
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      },
      async onAfterResponse(xhr) {
        console.log(xhr.responseText);
        if (xhr.status === 401) { // Token expired / invalid
          token = await WinsService.fetchImageUploadBatchToken();
        } else {
          try {
            const result = JSON.parse(xhr.responseText); // Response from Cloudflare
            if (!result.success) {
              token = await WinsService.fetchImageUploadBatchToken(); // retry upload
            }
          } catch (e) {
            token = await WinsService.fetchImageUploadBatchToken(); // retry upload (mostly 408 error)
          }
        }
      },
    });
    uppy.on('files-added', async (files) => { // Prevent calling cloud functions
      // Update mapping array (prefix & location)
      const latestMappings = [...new Set(files.filter(f => /^[A-Z]+$/.test(f.name[0])).map(f => f.name[0]))].map(prefixCode => ({
        prefixCode,
        locationId: canvas.bupPrefixLocationMappings.find(m => m.prefixCode == prefixCode)?.workLocationId,
      }));
      canvas.bupPrefixLocationMappings = latestMappings;

      // pre-fetch upload token
      if (!token) token = await WinsService.fetchImageUploadBatchToken();
    });
    uppy.on('complete', async (result) => {
      console.log('successful files:', result.successful);
      console.log('failed files:', result.failed); // TBC: if 1 file failed then delete all uploaded files then retryAll?
      token = null; // reset token

      // Add to batch upload job record tables
      const files = result.successful.map(r => {
        const result: any = r.response.body.result;
        const namePart = r.name.split(".")[0]; // exclude extension
        const prefixCode = namePart[0]; // A, B, C, D
        const floor = namePart.substring(1, namePart.length-2); // 1/2 digits after the prefix
        const idx = namePart.slice(-2); // 01, 02, 03, ...
        const relatedLocationId = (canvas.bupPrefixLocationMappings.find(m => m.prefixCode == prefixCode) || {}).workLocationId;
        const relatedPhotoRecord = getWorkPhotoRecordsByType(canvas.bupWorkLocationType).find(r => {
          //const parsedFloor = ((r['樓層'].match(/(\d+)/) || [])[0] || "").trim();
          const parsedFloor = r['樓層'].toString().split("/")[0];
          return parsedFloor == floor && r.subLocationId == relatedLocationId && (r.seqNumber == parseInt(idx) || r['照片編號'].endsWith(idx));
        });
        return {
          name: r.name,
          size: r.size,
          extension: r.extension,
          downloadLink: result.variants.find(v => v.includes("/public")) || result.variants[0],
          linkedWorkPhotoRecordId: relatedPhotoRecord?.id,
        }
      });
      const { job, jobFiles } = await WinsService.insertBatchUploadJob(projectId, files, canvas.bupTargetStep, canvas.bupWorkLocationType, canvas.bupRecognitionMode);
      console.log(jobFiles);
      canvas.bupUploadedFiles = jobFiles; // show in modal for fine-tune linked records
      uppy.clear(); // reset dashboard

      // Refresh project data
      const project = await ProjectService.getProjectById(projectId);
      store.commit('upsertProjects', [project]);

      // Subscribe to file changes
      subBatchUploadJobFileTable(job.id);
    });

    // Device (e.g. camera)
    const { refreshCamStreaming, isDeviceOnline, getAnchorAssetStatus, pointEntityTypes, getPointTypeObj, getDeviceLogMsg, alertIcons, } = utilsDevice();
    const numOfAssetsByType = (type) => (project.value.assets?.filter(a => a.workLocationId == canvas.workLocationId && a.type == type).length);
    const numOfAnchorsByStatus = (statusCode) => {
      if (!project.value.assets) return 0;
      const relatedAssets = project.value.assets.filter(a => a.workLocationId == canvas.workLocationId && a.type == 'anchor');
      const filteredAssets = relatedAssets.filter(a => {
        const statusObj = getAnchorAssetStatus(a.parts);
        return statusObj.code == statusCode;
      });
      return filteredAssets.length;
    }
    const todayDeviceAlerts = ref([]); // selected device alerts

    // User permissions
    const checkProjectUserPermission = (permission: any) => {
      if (projectId == 'demo') return true;
      return currUser.value.isInternal || project.value.userPermissions?.includes(permission);
    }

    // Show visible steps according to taken photos & 鐵狀態
    //const workSteps = ['定位', '打鑿', '除銹', '換鐵', '防銹', '回泥']; // TODO: no hard code here?
    //const workStepColors = ['red', '#FF8C00', '#FFCC00', 'green', 'lightblue', 'blue'];
    const workSteps = ['定位', '打鑿', '除銹', '防銹', '回泥']; // TODO: no hard code here?
    const workStepColors = ['red', '#FF8C00', '#FFCC00', 'lightblue', 'blue'];
    const getWorkPhotoRecordVisibleSteps: any = (cl: any) => {
      const visibleSteps = [], missingPhotoSteps = [];
      let currentStepColor = "", currentStep = "", currentStepPhotoLink = "";
      if (cl) {
        let reachedFirstNoPhotoStep = false;
        for (let i = 0; i < workSteps.length; i++) {
          const step = workSteps[i];
          if (['防銹', '除銹'].includes(step) && cl["破損類型"] != '結構破損') continue;
          /*if (step == '除銹') continue; // TBC: 除銹 step needed?
          if (step == '換鐵' && cl['鐵狀態'] != "有問題，需要換鐵") continue;
          if (step == '防銹' && cl['鐵狀態'] != "有問題，需要防銹") continue;*/
          if (!cl[`${step}相片`]) {
            const obj = { text: step, isDisabled: true }; // no photo, default disable
            if (!reachedFirstNoPhotoStep) {
              obj.isDisabled = false; // except the first missing photo step
              reachedFirstNoPhotoStep = true;
              currentStepColor = workStepColors[i];
              currentStep = step;
            }
            visibleSteps.push(obj);
            missingPhotoSteps.push(step);
          } else {
            //currentStep = step;
            currentStepPhotoLink = tmpGetPhotoLink(cl[`${step}相片`], true);
            visibleSteps.push({ text: step });
          }
        }
      }
      return { currentStep, currentStepPhotoLink, currentStepColor: currentStepColor || 'lightgreen',
              visibleSteps, missingPhotoSteps, }; // no missing photos = green done
    }

    const searchKeyword = ref(""); // photo code keyword
    const canvas = reactive({
      currWorkLocation: null,
      currWorkLocationFloorPlanPhotoLink: null,
      workLocationId: preSelectWorkLocationId || workLocations.value[0]?.id,
      selectedPointId: null,
      selectedPoint: {},
      selectedFeature: null,

      // Point info
      targetFloor: "", photoCode: "", seqNumber: "", damageType: "",
      damageAreaLength: "", damageAreaWidth: "", ironState: "",
      subLocationId: "", // for 內牆 only

      // More actions
      isPopoverOpened: false,
      popoverEvent: null,

      // Other
      stepImgLoadStates: [],
      isSearching: false, // toggle searchbar

      // Toggle visbility
      showCurrStepPhotos: false,
      showPhotoCodeLabels: true,
      showPhotoStats: true,
      showBackgroundImg: true, // mainly for desktop mode

      // New entity Form (for adding new points / devices)
      isPointModalOpened: false,
      targetCoordinates: [],
      dummyFeature: null, // tmp visualize point location on map
      pointTargetEntityType: 'workPhotoRecord',

      // Devices
      deviceId: null, // can scan QR code
      deviceMinor: null, // beacon minor
      isAttendanceDevice: null, // for tracking attendance
      workerName: "", // helmet linked worker name
      simPhone: "", // linked SIM phone number

      // Assets
      linkingDeviceAssetId: null,
      linkingDeviceAssetLabel: null,

      // Batch creation
      diffXPerMeter: null,
      horizontalSpacing: 3,
      diffMetersBetweenFloors: 6.3,

      // Overview
      loadingMapPhotos: false,
      prevIsOverview: false,

      // Modals
      isProjectUserModalOpened: false, // project user

      // Batch import work photos
      isBatchUploadPhotoModalOpened: false,
      bupTargetStep: "定位",
      bupWorkLocationType: "內牆",
      bupRecognitionMode: "manual",
      bupPrefixLocationMappings: [],
      bupUploadedFiles: [], // batch upload file records (for updating mappings)
      isEditWorkRecordModalOpened: false, // for editing linked work photo record
      editWorkRecordModalSearchKeyword: "", // filtering list of work photo records
      bupTargetJobFile: null,
      selectedBatchUploadJob: null,
      bupSupabaseChannel: null,

      // Photo gallery for specific location / floor plan
      isWorkStepGalleryModalOpened: false,
      wsgWorkPhotoRecords: [], // for filtering photos of specific locations
      wsgVisbleSteps: [],
      wsgTitle: "",
      wsgTargetStep: "定位", // filter photos by steps
    });
    const currProjectUser = reactive({
      id: "",
      firstName: "",
      phone: "",
      roleId: "",
    });

    // Selected device / asset / child asset
    const selectedDevice = (targetKey = null) => {
      const obj = canvas.selectedFeature.get('deviceObj');
      return targetKey ? obj[targetKey] : obj;
    };
    const selectedAsset = (targetKey = null) => {
      const obj = canvas.selectedFeature.get('assetObj');
      return targetKey ? obj[targetKey] : obj;
    };
    const selectedAssetPart = (partType, targetKey = null) => {
      const obj = canvas.selectedFeature.get('assetObj').parts.find(p => p.type == partType);
      return targetKey ? obj[targetKey] : obj;
    };
    const formatVal = (val, unit, toFixedDigits = 0) => ((val || val == 0) ? `${val.toFixed(toFixedDigits)} ${unit}` : `-`);
    const latestDeviceVal = (key) => ((selectedDevice('latestDeviceData') || {})[key]);
    const latestParentAssetData = (targetKey = null) => {
      let dataObj = selectedAsset('latestAssetData');
      if (!dataObj) { // check parts for latest data
        const mergedDataObj = {};
        const partDataObjs = selectedAsset('parts').map(pa => pa.latestAssetData).filter(d => !!d).sort((a, b) => (new Date(a.timestamp).getTime()-new Date(b.timestamp).getTime()));
        for (const obj of partDataObjs) {
          for (const [key, val] of Object.entries(obj)) {
            mergedDataObj[key] = val; // latest data will override old data
          }
        }
        dataObj = mergedDataObj;
      }
      return targetKey ? (dataObj || {})[targetKey] : dataObj;
    }
    const latestAssetPartVal = (partType, key) => ((selectedAssetPart(partType, 'latestAssetData') || {})[key]);

    // Methods / Listeners
    const setPopoverOpen = (state: boolean, ev = null) => {
      canvas.popoverEvent = ev; 
      canvas.isPopoverOpened = state;
    }
    const setCanvasPointInfo = (targetFloor = "", photoCode = "", seqNumber = "", damageType = "", damageAreaLength = "", damageAreaWidth = "", ironState = "", subLocationId = "",
                                pointTargetEntityType = "workPhotoRecord", deviceId = "", deviceMinor = "", workerName = "", isAttendanceDevice = null, iccid = "") => {
      canvas.targetFloor = targetFloor;
      canvas.photoCode = photoCode;
      canvas.seqNumber = seqNumber;
      canvas.damageType = damageType;
      canvas.damageAreaLength = damageAreaLength;
      canvas.damageAreaWidth = damageAreaWidth;
      canvas.ironState = ironState;
      canvas.subLocationId = subLocationId;
      canvas.pointTargetEntityType = pointTargetEntityType;
      canvas.deviceId = deviceId;
      canvas.deviceMinor = deviceMinor;
      canvas.workerName = workerName;
      canvas.isAttendanceDevice = isAttendanceDevice;
      canvas.simPhone = iccid;
    }
    const handleFloorChange = () => {
      const { targetFloor, workLocationId, currWorkLocation: wl, } = canvas;
      // TBC: 1.0: leave photo code empty for flexibility
      
      if (targetFloor) {
        const sameFloorPhotoRecords = project.value.workPhotoRecords.filter(l => l['勘察位置'] == workLocationId && l['樓層'] == targetFloor);
        const floorNumber = targetFloor.split("/")[0], pointCount = sameFloorPhotoRecords.length;
        const photoCode = `${wl.photoCodePrefix || ''}${isNaN(+floorNumber) ? floorNumber : floorNumber.padStart(2, '0')}F${(pointCount+1).toString().padStart(2, '0')}`;
        canvas.photoCode = photoCode.replace(/^RFRF/, "RF"); // Update photo code dynamically
      } else {
        canvas.photoCode = "";
      }
    }
    const handleSubLocationChange = () => {
      const { targetFloor, subLocationId, workLocationId, } = canvas;
      if (targetFloor && subLocationId) {
        const subLocation = workLocations.value.find(p => p.id == subLocationId);
        const sameFloorPhotoRecords = project.value.workPhotoRecords.filter(l => l['勘察位置'] == workLocationId && l['樓層'] == targetFloor && l.subLocationId == subLocationId);
        const floorNumber = targetFloor.split("/")[0], pointCount = sameFloorPhotoRecords.length;
        const photoCode = `${subLocation.photoCodePrefix || ''}${isNaN(+floorNumber) ? floorNumber : floorNumber.padStart(2, '0')}F${(pointCount+1).toString().padStart(2, '0')}`;
        canvas.photoCode = photoCode; // Update photo code dynamically
      } else {
        canvas.photoCode = "";
      }
    }
    

    /** 
     * OpenLayers variables & functions
     */
    let map; // Main OL map
    let source; // Vector Source
    let modifyInteraction;

    const baseRadiusMeters = 5;

    // Function to create a style based on the current zoom level
    function createStyleForFeature(feature, resolution, overrideColor = null) {
      resolution = Math.min(2, resolution+0.25);
      const isOnline = isDeviceOnline(feature.get('deviceObj'));

      // For highlighting devices / points
      const borderStyle = new Style({
        image: new CircleStyle({
          radius: 20 / resolution + 5, // Slightly larger radius than the icon to serve as a border
          fill: new Fill({ color: 'rgba(0, 0, 0, 0)', }), // Transparent fill
          stroke: new Stroke({
            color: '#fda619', // Green color or any color of your choice for the selection border
            width: 2, // Width of the stroke
          }),
        }),
        zIndex: 2,
      });
      const checkAddBorderStyle = (finalStyles) => {
        if (feature.getId() == canvas.selectedPointId) finalStyles.push(borderStyle);
        return finalStyles;
      }
      
      // Base style
      const baseIconStyleObj: any = {
        anchor: [0.5, 1], // Anchor the bottom center of the photo to the point location
        height: 35/resolution,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        displacement: [0, 0],
      }

      // Camera devices
      if (feature.get('type') == 'bcam') {
        return checkAddBorderStyle([new Style({
          image: new Icon({
            ...baseIconStyleObj,
            src: require(isOnline ? '@/assets/icons/cctv.png' : '@/assets/icons/cctv_offline.png'), // URL of the photo
            //src: require('@/assets/icons/cctv.png'), // URL of the photo
            displacement: [0, -20/resolution],
          }),
        })]);
      }

      // Smart Helmet
      if (feature.get('type') == 'helmet') {
        return checkAddBorderStyle([new Style({
          image: new Icon({
            ...baseIconStyleObj,
            src: require(isOnline ? '@/assets/icons/helmet_map_marker.png' : '@/assets/icons/helmet_map_marker_offline.png'), // URL of the photo
          }),
        })]);
      }

      // Wall Anchors
      if (feature.get('type') == 'anchor') {
        const statusObj = getAnchorAssetStatus(feature.get('assetObj').parts);
        return checkAddBorderStyle([new Style({
          image: new Icon({
            ...baseIconStyleObj,
            src: require(`@/assets/icons/anchor-${statusObj.code}.png`), // URL of the photo
            displacement: [0, -15/resolution],
          }),
        })])
      }

      // Positioning Beacons
      if (feature.get('type') == 'beacon') {
        return checkAddBorderStyle([new Style({
          image: new Icon({
            ...baseIconStyleObj,
            src: require(feature.get('deviceObj').isTrackingAttendance ? '@/assets/icons/home.png' : '@/assets/icons/radar.svg'), // URL of the photo
            height: 20/resolution,
            displacement: [0, -15/resolution],
          }),
        })]);
      }
      
      // Work Photo Record Points
      const geometryType = feature.getGeometry().getType();
      if (geometryType === 'Point') {
        // Style for the point
        const radiusPixels = baseRadiusMeters / resolution;
        if (feature.get('pointObj')) { // label point
          const labelText = feature.get('pointObj')["照片編號"];
          return canvas.showPhotoCodeLabels && labelText ? new Style({
            text: new Text({
              text: labelText,
              font: `${8+radiusPixels}px Calibri,sans-serif`,
              overflow: true,
              fill: new Fill({ color: '#000' }),
              stroke: new Stroke({ color: '#fff', width: 3 }),
              offsetX: 10, // Horizontal offset for the label
            }),
          }) : null;
        }
        let currStepColor = 'rgba(255, 0, 0, 0.5)', labelText = ''; // default color & text
        const cl = getWorkPhotoRecordById(feature.getId());
        let stepPhotoStyle = null;
        if (cl) {
          const { currentStep, currentStepColor, currentStepPhotoLink } = getWorkPhotoRecordVisibleSteps(cl);
          currStepColor = currentStepColor;
          labelText = currentStep;
          //const photoCodeNumMatches = cl['照片編號'].match(/\d+$/);
          //labelText = photoCodeNumMatches ? photoCodeNumMatches[0].replace(/^0+/, "") : cl['照片編號'];
          
          // Create the style for the photo (current step)
          if (canvas.showCurrStepPhotos && currentStepPhotoLink) {
            stepPhotoStyle = new Style({
              image: new Icon({
                src: currentStepPhotoLink?.replace('/public', '/icon'), // URL of the photo (for Cloudflare can resize to icon)
                height: 40/resolution,
                anchor: [0.5, 1], // Anchor the bottom center of the photo to the point location
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                displacement: [0, -30/resolution],
              }),
            });
          }
        }
        const finalStyles = [new Style({ // check location point
          image: new CircleStyle({
            radius: radiusPixels,
            fill: new Fill({
              color: overrideColor || currStepColor || '',
            }),
            stroke: new Stroke({
              color: overrideColor || 'black',
              width: 2/resolution,
            })
          }),
          text: new Text({
            text: labelText,
            font: `${6+radiusPixels}px Calibri,sans-serif`,
            overflow: true,
            fill: new Fill({ color: '#000' }),
            stroke: new Stroke({ color: '#fff', width: 3 }),
            offsetY: -10, // Horizontal offset for the label
          }),
        })];
        if (stepPhotoStyle) finalStyles.unshift(stepPhotoStyle);
        if (feature.getId() == canvas.selectedPointId) finalStyles.push(borderStyle);
        return finalStyles;
      }
      else if (geometryType === 'LineString') {
        // Style for the leader line
        const labelText = (feature.get('pointObj') || {})["照片編號"];
        return (canvas.showPhotoCodeLabels && labelText) ? new Style({
          stroke: new Stroke({
            color: 'rgba(100, 100, 100, 0.5)', // Semi-transparent grey line
            width: 1
          }),
        }) : null;
      }
      return null;
    }
    const createModifyStyleForFeature = (feature, resolution) => {
      const featureWithId = (feature.get('features') || [feature]).find(f => f.getId());
      return featureWithId ? createStyleForFeature(feature, resolution, 'rgba(0, 153, 255, 1)') : null;
    };
    
    // Calculate the pixel tolerance based on resolution
    function calculatePixelTolerance(newResolution) {
      const pixelTolerance = baseRadiusMeters / newResolution;
      return pixelTolerance * 1.5; // Adjust the padding as needed
    }
    const createModifyInteraction = (pixelTolerance = 15, selectedFeature = null, allowModifyFeatures = null) => {
      const options = {
        condition: (e) => {
          if (!checkProjectUserPermission('canMovePoints')) {
            return false; // not allow moving points
          }
          if (allowModifyFeatures) return true; // focusing specific point

          const features = map.getFeaturesAtPixel(e.pixel, { hitTolerance: 0 });
          //return features.length >= 2 && features[0].getGeometry().getType() != 'LineString';
          return features.some(f => (f.getId() != null)); // skip moving text / lines
        },
        //insertVertexCondition: never,
        source,
        pixelTolerance,
        snapToPointer: false,
        style: createModifyStyleForFeature,
      }
      if (allowModifyFeatures) options['features'] = new Collection(allowModifyFeatures);
      if (selectedFeature) selectedFeature.setStyle(createModifyStyleForFeature(selectedFeature, map.getView().getResolution()));
      const modify = new Modify(options);
      let undoStack = [];
      modify.on('modifystart', (evt) => {
        evt.features.forEach((feature) => {
          // Clone the original geometry and push it to the undo stack
          const originalGeometry = feature.getGeometry().clone();
          undoStack.push({feature, originalGeometry});
        });
      });
      modify.on('modifyend', (e) => {
          const feature = e.features.getArray()[0];
          const entityId = feature.getId();
          if (entityId) {
            presentPrompt(t("confirmMove"), "", () => {
              // Update DB point coordinates X & Y
              const pointGeometry: any = feature.getGeometry();
              const [pointX, pointY] = pointGeometry.getCoordinates();

              switch (feature.get('type')) {
                case 'point':
                  // Update work photo record table
                  ProjectService.updateCheckLocationPointXY(entityId, pointX, pointY);
                  break;
                default:
                  if (getPointTypeObj(feature.get('type'))['table'] == 'assets') {
                    // Update assets table
                    AssetService.updateAssetPointXY(entityId, pointX, pointY);
                  } else {
                    // Update devices table
                    DeviceService.updateDevicePointXY(entityId, pointX, pointY);
                  }
                  break;
              }
              canvas.selectedFeature?.setStyle(null);
              setSelectedPoint(null, {}, null); // unselect active point
              undoStack = [];
            }, "", () => {
              for (const modition of undoStack) {
                modition.feature.setGeometry(modition.originalGeometry);
              }
              undoStack = [];
            })
          } else {
            undoStack = [];
          }
      });
      return modify;
    }

    // Function: INIT OpenLayer (based on selected floor plan)
    // Show saved points
    // A simple function to adjust label position; this should be improved
    const labelPositions = []; // An array to store label positions
    function adjustLabelPosition(initialPosition) {
      let position = initialPosition;
      
      for (let i = 0; i < labelPositions.length; i++) {
        const existingLabel = labelPositions[i];
        
        // Simple collision detection logic
        // Note: This is a placeholder; you'd need to calculate text dimensions
        // based on font size, text length, etc.
        if (Math.abs(existingLabel[0] - position[0]) < 50 && Math.abs(existingLabel[1] - position[1]) < 20) {
          // Adjust position to avoid overlap
          // Here we just shift the label to the right; you can implement more complex logic
          position = [position[0] + 50, position[1]];
          break;
        }
      }

      // Store the label position
      labelPositions.push(position);
      
      return position;
    }
    const addPoint = (id, coordinates, pointObj = null, type = "point", deviceObj = null, assetObj = null) => {
      const feature = new Feature({
        geometry: new Point(coordinates),
        groupId: id,
        type,
        deviceObj,
        assetObj,
      });
      feature.setId(id);
      source.addFeature(feature);

      if (type == "point") {
        const labelOffset = map.getView().getResolution() * 100;
        const labelCoordinates = adjustLabelPosition([coordinates[0] + labelOffset, coordinates[1]]);
        const labelFeature = new Feature({
          geometry: new Point(labelCoordinates),
          //label: labelText,
          groupId: id,
          pointObj,
        });
        source.addFeature(labelFeature);

        // Create the leader line feature
        const leaderLineFeature = new Feature({
          geometry: new LineString([coordinates, labelCoordinates]),
          groupId: id,
          pointObj,
        });
        source.addFeature(leaderLineFeature);
      }
      return feature;
    }
    const setSelectedPoint = (pointId, point, feature, allowModifyFeatures = null) => {
      canvas.selectedPointId = pointId;
      canvas.selectedPoint = point;
      canvas.selectedFeature = feature;
      canvas.stepImgLoadStates = [];

      if (feature) {
        // For asset, fetch recent logs if not yet done
        const assetObj = feature.get('assetObj');
        if (assetObj && !assetObj.fetchedRecentData) {
          ReportService.queryData({ assetId: assetObj.id, targetDataTable: 'device_logs', limit: 10, }).then(res => {
            assetObj.logs = res.data || [];
            assetObj.fetchedRecentData = true;
          });
        }

        //map.un('singleclick', mapSingleClickListener);
        map.getInteractions().forEach((interaction) => (interaction.setActive(false)));
        map.removeInteraction(modifyInteraction);
        modifyInteraction = createModifyInteraction(10000, feature, allowModifyFeatures); // move point by dragging anywhere on the map
        map.addInteraction(modifyInteraction); // TBC: may get mis-drag by workers

        // Get the feature's geometry or calculate its centroid if it's not a Point
        const geometry = feature.getGeometry();
        const centroid = geometry.getType() === 'Point' ? geometry.getCoordinates() : getCenter(geometry.getExtent());
        const pixel = map.getPixelFromCoordinate(centroid);
        const yOffset = map.getSize()[1] / 4; // Adjust the fraction to set the desired offset
        const newPixel = [pixel[0], pixel[1] + yOffset];
        const newCenter = map.getCoordinateFromPixel(newPixel);
        map.getView().setCenter(newCenter);
        
        window.history.replaceState('', '', `/ol/${projectId}/${selectedWorkLocationType.value}/${canvas.workLocationId}/${pointId || ''}`); // update the path (for forwarding)
      } else {
        const resolution = map.getView().getResolution();
        map.removeInteraction(modifyInteraction);
        const newPixelTolerance = calculatePixelTolerance(resolution);
        modifyInteraction = createModifyInteraction(newPixelTolerance);
        map.addInteraction(modifyInteraction);
        map.getInteractions().forEach((interaction) => interaction.setActive(true));
        map.on('singleclick', mapSingleClickListener);

        window.history.replaceState('', '', `/ol/${projectId}/${selectedWorkLocationType.value}/${canvas.workLocationId}`); // reset the path
      }
    }
    const clearPointOnCanvas = (featureId) => {
      source.getFeatures().filter(f => f.get('groupId') == featureId).forEach(feature => {
        feature.setStyle(undefined);
        source.removeFeature(feature); // Remove dummy point
      })
    }
    const clearDummyFeature = (unselectActivePoint = false) => {
      if (canvas.dummyFeature) clearPointOnCanvas(canvas.dummyFeature.getId()); // Remove dummy point
      canvas.targetCoordinates = [];
      canvas.dummyFeature = null;
      if (unselectActivePoint) setSelectedPoint(null, {}, null); // unselect active point
    }
    const closePointModal = () => {
      presentPrompt("", t('confirmLeave'), () => {
        if (!canvas.selectedPointId) clearDummyFeature(true);
        canvas.isPointModalOpened = false;
        setCanvasPointInfo(); // reset form
      });
    }
    const mapSingleClickListener = async (evt) => {
      //const hitTolerance = calculatePixelTolerance(map.getView().getResolution());
      const features = map.getFeaturesAtPixel(evt.pixel, { hitTolerance: 10 });
      const targetFeature = features.find(f => f.getId() != null);
      const coordinates = evt.coordinate;
      if (features.length === 0 || !targetFeature) {
        if (canvas.selectedPointId == null && !canvas.selectedFeature) {
          if (checkProjectUserPermission('canAddNewPoints')) {
            // create dummy points first
            const dummyFeature = addPoint(`dp${uniqueId()}`, coordinates, {});
            setSelectedPoint(null, {}, dummyFeature);

            // open modal for adding new points
            canvas.isPointModalOpened = true;
            canvas.targetCoordinates = coordinates;
            canvas.dummyFeature = dummyFeature;
            canvas.subLocationId = ""; // reset selected 位置

            // Default use plan floor
            canvas.targetFloor = canvas.currWorkLocation['樓層'] || "";

            // Estimate floor for selected point
            if (!canvas.targetFloor) {
              const currPointY = coordinates[1], floorMaxDiffY = 40;
              const findNearbyEntities = (entityKey, workLocationIdKey = 'workLocationId', floorKey = 'floor') => {
                if (!canvas.targetFloor) {
                  const nearbyEnts = project.value[entityKey].filter(l => l[workLocationIdKey] == canvas.workLocationId && l.pointY && Math.abs(l.pointY-currPointY) <= floorMaxDiffY)
                                                              .sort((a, b) => Math.abs(a.pointY-currPointY)-Math.abs(b.pointY-currPointY));
                  if (nearbyEnts.length > 0) {
                    const ent = nearbyEnts[0], diffY = ent.pointY-currPointY;
                    const floorText = ent[floorKey] || "", floorCode = floorText.split("/")[0], floorNum = Number(floorCode), threshold = floorMaxDiffY/1.5;
                    canvas.targetFloor = `${isNaN(floorNum) ? floorCode : (diffY > threshold ? floorNum-1 : (diffY < threshold*-1 ? floorNum+1 : floorNum))}/F`;
                  }
                }
              }
              findNearbyEntities('workPhotoRecords', '勘察位置', '樓層'); // check nearby photo points
              findNearbyEntities('devices'); // check nearby devices
              //findNearbyEntities('assets'); // check nearby assets
            }
            handleFloorChange();
          }
        }
        else {
          if (canvas.isPointModalOpened) {
            closePointModal();
          } else {
            canvas.selectedFeature?.setStyle(null);
            setSelectedPoint(null, {}, null); // unselect active point on click empty place
          }
        }
      } else {
        canvas.isPointModalOpened = false;
        const entityType = targetFeature?.get('type');
        if (entityType == 'point') {
          const point = getWorkPhotoRecordById(targetFeature.getId());
          //setSelectedPoint(point.id, point, targetFeature); // select active point
          canvas.selectedFeature?.setStyle(null);
          setSelectedPoint(point.id, point, targetFeature, features); // select active point
        }
        else if (getPointTypeObj(entityType)['table'] == 'assets') {
          const asset = project.value.assets.find(a => a.id == targetFeature.getId());
          canvas.selectedFeature?.setStyle(null);
          setSelectedPoint(asset.id, asset, targetFeature, features); // select active point
        }
        else {
          const device = project.value.devices.find(d => d.id == targetFeature.getId());
          canvas.selectedFeature?.setStyle(null);
          setSelectedPoint(device.id, device, targetFeature, features); // select active point

          // refresh cam stream
          if (entityType == 'bcam') {
            refreshCamStreaming(device.vpnIp);
          }
          // refresh Google Map
          else if (entityType == 'helmet') {
            setTimeout(() => {
              const { latitude: lat, longitude: lng } = selectedDevice('helmet');
              const map = new window["google"].maps.Map(document.getElementById('helmet_map'), {
                center: { lat, lng },
                zoom: 18,
              });
              const markers = [
                { position: { lat, lng }, title: 'Worker' },
              ];
              for (const marker of markers) {
                new window["google"].maps.Marker({
                  position: marker.position,
                  map: map,
                  title: marker.title,
                  icon: {
                    url: require('@/assets/icons/helmet_map_marker.png'),
                    scaledSize: new window["google"].maps.Size(50, 50),
                  }
                });
              }
            }, 500)
          }
        }
      }
    }
    const renderOLMap = (targetWorkLocationId = null, keepCurrentViewport = false, scrollToWorkLocationItem = true) => {
      let currZoom, currCenter;
      if (map) {
        currZoom = map.getView().getZoom();
        currCenter = map.getView().getCenter();
        map.setTarget(null);
        map = null;
      }
      const workLocation = workLocations.value.find(p => p.id == (targetWorkLocationId || canvas.workLocationId));
      if (workLocation) {
        if (!targetWorkLocationId) {
          canvas.currWorkLocation = workLocation; // set plan on change by users
          window.history.replaceState('', '', `/ol/${projectId}/${selectedWorkLocationType.value}/${canvas.workLocationId}`);
        }

        const { id: planId, floorPlanPhotoLink, imgHeight, imgWidth, initialZoom, } = workLocation;
        const extent = [0, 0, Number(imgWidth), Number(imgHeight)]; // dynamic extent based on floor plan size
        const vectorExtent = [0, 0, Number(imgWidth)+150, Number(imgHeight)];
        canvas.currWorkLocationFloorPlanPhotoLink = floorPlanPhotoLink;

        // Floor plan label (A面 / B面 / C面 / ...)
        const textStyle = new Style({
          text: new Text({
            text: workLocation['代號'],
            font: '28px Calibri,sans-serif',
            fill: new Fill({ color: [255, 255, 255, 1] }),
            backgroundFill: new Fill({ color: [255, 0, 0, 0.6] }),
            padding: [2, 2, 2, 2],
          })
        });
        const labelFeature = new Feature({ geometry: new Point([Number(imgWidth)+25, Number(imgHeight)-25]) });
        labelFeature.setStyle(textStyle)

        // Vector layer: for drawing / modifying points on map
        source = new VectorSource({ features: [labelFeature] });
        const vectorLayer = new VectorLayer({
          source: source,
          style: createStyleForFeature,
          extent: vectorExtent,
        });

        // Image Layer: floor plan / 外牆圖
        const projection = new Projection({
          code: 'xkcd-image',
          units: 'pixels',
          extent: extent,
        });
        const imgLayer = new ImageLayer({
          source: new Static({
            url: getProxyImgLink(floorPlanPhotoLink),
            crossOrigin: 'Anonymous',
            projection: projection,
            imageExtent: extent,
          }),
        });


        // Init the map
        map = new Map({
          controls: defaultControls().extend([new FullScreen()]),
          layers: [imgLayer, vectorLayer], // 2 layers: 1 for map, another 1 for point markers
          target: 'map',
          view: new View({
            projection: projection,
            center: keepCurrentViewport ? currCenter : getCenter(extent),
            zoom: keepCurrentViewport ? currZoom : (initialZoom || 1),
            maxZoom: 8,
            enableRotation: false,
          })
        });
        map.on('loadstart', () => {
          map.getTargetElement().classList.add('spinner');
        });
        map.on('loadend', () => {
          map.getTargetElement().classList.remove('spinner');
          if (preSelectWorkPhotoRecordId) {
            const relatedFeatures = source.getFeatures().filter(f => f.get('groupId') == preSelectWorkPhotoRecordId);
            const targetFeature = relatedFeatures.find(f => f.getId() != null);
            if (targetFeature) {
              const point = getWorkPhotoRecordById(preSelectWorkPhotoRecordId);
              setSelectedPoint(point.id, point, targetFeature, targetFeature); // select active point
            }
            preSelectWorkPhotoRecordId = null;
          }
        });
        map.getView().on('change:resolution', (event) => {
          const newPixelTolerance = calculatePixelTolerance(event.target.getResolution());
          map.removeInteraction(modifyInteraction);
          modifyInteraction = createModifyInteraction(newPixelTolerance);
          map.addInteraction(modifyInteraction);
        });

        // Load previously stored points (according to selected floor plan)
        const relatedWorkPhotoRecords = project.value.workPhotoRecords.filter(l => l['勘察位置'] == planId);
        for (const l of relatedWorkPhotoRecords) {
          const { id, pointX, pointY, } = l;
          addPoint(id, [Number(pointX), Number(pointY)], l);
        }

        // Load provisioned devices (cam / beacons / vss) & assets
        if (checkProjectUserPermission('canManageDevices')) {
          const relatedDevices = project.value.devices.filter(d => d.workLocationId == planId);
          for (const d of relatedDevices) {
            const { id, pointX, pointY, type, } = d;
            addPoint(id, [Number(pointX), Number(pointY)], null, type, d);
          }
          DeviceService.getTodayDeviceAlerts().then(res => {
            todayDeviceAlerts.value = res; // retrieve all device alerts (rulebreak screenshots)
          })

          // Add assets as well
          const relatedAssets = project.value.assets.filter(d => d.workLocationId == planId);
          for (const a of relatedAssets) {
            const { id, pointX, pointY, type, } = a;
            addPoint(id, [Number(pointX), Number(pointY)], null, type, null, a);
          }
        }

        // Event: Relocate points
        modifyInteraction = createModifyInteraction(); // initial modify interaction
        map.addInteraction(modifyInteraction);

        /**
         * Add a click handler to the map
         */
        map.on('singleclick', mapSingleClickListener);
      }

      // Left pane scroll into view
      setTimeout(() => {
        if (canvas.workLocationId && scrollToWorkLocationItem) {
          // scroll into list item
          const el = document.querySelector(`#item-${canvas.workLocationId}`);
          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start'
            });
          }
        }
      }, 300);
    }

    // Turn map into an image (and return data URL)
    const getMapCanvasDataURL = async (planId, imgHeight, imgWidth) => {
      renderOLMap(planId); // refresh the map first

      // Ensure map fully shown
      const vectorExtent = [0, 0, Number(imgWidth)+150, Number(imgHeight)];
      const extentWidth = getWidth(vectorExtent), extentHeight = getHeight(vectorExtent);

      // Get map container
      const mapContainer = document.getElementById('map');
      const originalSize = [mapContainer.offsetWidth, mapContainer.offsetHeight];
      
      // Resize the map container
      mapContainer.style.width = `${extentWidth}px`;
      mapContainer.style.height = `${extentHeight}px`;
      mapContainer.style.maxHeight = 'none';

      // Update OpenLayers with the new size
      map.updateSize();
      map.getView().fit(vectorExtent, { size: map.getSize(), padding: [10, 10, 10, 10] });

      // Export image and add to PDF
      await new Promise((resolve, reject) => {
        map.once('rendercomplete', resolve);
      });
      const mapCanvas = document.createElement('canvas');
      mapCanvas.width = extentWidth;
      mapCanvas.height = extentHeight;
      const mapContext = mapCanvas.getContext('2d');
      mapContext.fillStyle = "white";
      mapContext.fillRect(0, 0, mapCanvas.width, mapCanvas.height);
      Array.prototype.forEach.call(document.querySelectorAll('.ol-layer canvas'), (canvas) => {
        if (canvas.width > 0) {
          // Get the transform parameters from the style's transform matrix
          const opacity = canvas.parentNode.style.opacity;
          mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
          const transform = canvas.style.transform;

          // Apply the transform to the export map context
          const matrix = transform.match(/^matrix\(([^\(]*)\)$/)[1].split(',').map(Number);
          CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
          mapContext.drawImage(canvas, 0, 0);
        }
      });
      // Reset opacity and transform before adding to PDF
      mapContext.globalAlpha = 1;
      mapContext.setTransform(1, 0, 0, 1, 0, 0);

      const mapCanvasDataURL = mapCanvas.toDataURL('image/jpeg');

      // Reset the map container to its original size
      mapContainer.style.width = `${originalSize[0]}px`;
      mapContainer.style.height = `${originalSize[1]}px`;
      mapContainer.style.maxHeight = `800px`;

      return { extentHeight, extentWidth, mapCanvasDataURL };
    }

    // Supabase real-time subscriptions
    const handleChangedBatchUploadJob = (payload) => {
      console.log(payload);
      const obj = convertKeysToCamelCase(payload.new);
      store.commit('upsertBatchUploadJob', obj);
    }
    const handleChangedWorkPhotoRecord = (payload) => {
      console.log(payload);
      const obj = convertKeysToCamelCase(payload.new);
      if (obj.status == 'trashed') store.commit('deleteCheckLocation', { projectId, id: obj.id });
      else {
        for (const key in obj) {
          if (key.endsWith('相片')) {
            delete obj[key]; // not sync 相片 columns
          }
        }
        store.commit('upsertCheckLocation', obj);
      }
      renderOLMap(canvas.workLocationId, true);
    }
    const handleChangedWorkPhotoRecordStep = (payload) => {
      console.log(payload);
      const obj = convertKeysToCamelCase(payload.new);
      store.commit('upsertCheckLocation', { projectId, id: obj.recordId, [`${obj.step}相片`]: obj.photoLink });
      renderOLMap(canvas.workLocationId, true);
    }
    const handleUpdatedDeviceData = (payload) => {
      store.commit('upsertDevice', convertKeysToCamelCase(payload.new));
      renderOLMap(canvas.workLocationId, true);
    }
    const handleNewDeviceAlert = (payload) => {
      todayDeviceAlerts.value.push(convertKeysToCamelCase(payload.new));
    }
    const subscribeSupabaseTables = () => {
      if (channel) return; // already subscribed
      channel = supabase.channel('any');

      // Set up project work photo data change event handler
      channel
        .on('postgres_changes', { event: '*', schema: 'public', table: 'project_work_photo_records', filter: `project_id=eq.${projectId}` }, handleChangedWorkPhotoRecord)
        .on('postgres_changes', { event: '*', schema: 'public', table: 'project_work_photo_record_steps', filter: `project_id=eq.${projectId}` }, handleChangedWorkPhotoRecordStep)
        .on('postgres_changes', { event: '*', schema: 'public', table: 'batch_upload_jobs', filter: `project_id=eq.${projectId}` }, handleChangedBatchUploadJob);
      
      // Set up device data change event handler
      if (checkProjectUserPermission('canManageDevices')) {
        channel
          .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'devices', filter: 'type=eq.helmet' }, handleUpdatedDeviceData)
          .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'device_alerts' }, handleNewDeviceAlert)
      }

      // Subscribe to specified tables
      channel.subscribe(async (status) => {
        console.log(status);
        if (status !== 'SUBSCRIBED') { return }
      });
    }

    // User app preferences (view settings)
    const syncUserAppPreferences = () => {
      const { involvingProjects, appPreferences: p } = currUser.value;
      const keys = ['showCurrStepPhotos', 'showPhotoCodeLabels', 'showPhotoStats', 'showBackgroundImg'];
      if (p) {
        for (const key of keys) {
          if (key in p) {
            canvas[key] = p[key];

            // Toggle showing background image
            if (key == 'showBackgroundImg' && canvas[key] == true) {
              document.body.classList.add('tech-bg');
            }
          }
        }
      }
      if (involvingProjects && involvingProjects.find(p => p.projectId == projectId)) {
        ProjectService.updateUserLastAccessProjectTime(projectId); // update last access time
      }
    }
    onIonViewDidEnter(() => {
      renderOLMap();
      if (projectId) {
        if (!project.value.fetchedDetails) store.dispatch('fetchProjectDetails', { id: projectId }); // fetch product details
      }
      subscribeSupabaseTables(); // Main: subscribe real-time helmet locations
      import('@/assets/TaipeiSansTCBeta-Regular-normal.js');
      syncUserAppPreferences(); // view preferences (e.g. show photo stat or not)
    })
    onIonViewWillLeave(() => {
      if (channel) channel.unsubscribe(); // leave the channel
    });
    watch(currUser, () => {
      syncUserAppPreferences(); // view preferences (e.g. show photo stat or not)
    });
    watch(workLocations, (curr, prev) => {
      if (!prev || prev.length == 0 || curr[0]?.id != prev[0]?.id) {
        canvas.workLocationId = preSelectWorkLocationId || workLocations.value[0]?.id;
        renderOLMap();
      }
    })
    watch(project, () => {
      if (!project.value.fetchedDetails) store.dispatch('fetchProjectDetails', { id: projectId }); // fetch product details
      subscribeSupabaseTables(); // Main: subscribe real-time helmet locations
    })
    watch(searchKeyword, (currKeyword) => {
      const relatedWorkPhotoRecords = project.value.workPhotoRecords.filter(l => {
        return l['勘察位置'] == canvas.workLocationId && l['照片編號']?.toLowerCase().includes(currKeyword.toLowerCase());
      });
      const relatedGroupIds = relatedWorkPhotoRecords.map(r => r.id);
      for (const f of source.getFeatures()) {
        if (!currKeyword || relatedGroupIds.includes(f.get('groupId'))) {
          f.setStyle(null); // show the feature
        } else {
          f.setStyle(new Style({})); // hide the feature
        }
      }
      source.changed();
    })
    watch(selectedWorkLocationType, () => {
      canvas.workLocationId = workLocations.value[0]?.id;
      renderOLMap();
    })
    
    return {
      // icons
      home, people, calendar, newspaper, compass, personCircle, globeOutline, construct, notifications, scan,
      chevronBack, chevronForward, trashOutline, close, eye, eyeOff, camera, checkmark,
      ellipsisHorizontal, ellipsisVertical, downloadOutline, search, imagesOutline, text, qrCodeOutline,
      arrowBack, statsChart, cloudOfflineOutline, batteryCharging, videocamOutline, locationOutline, homeOutline,
      colorWandOutline, link, unlink, alertCircleOutline, informationCircleOutline, barbellOutline,
      statsChartOutline, analytics, thermometerOutline, waterOutline, cloudyOutline, timeOutline, colorPaletteOutline,
      add, mapOutline, pencil, cloudUploadOutline, logInOutline, buildOutline, bluetooth,
      
      // variables
      isMenuOpened,
      currUser, userLoggedIn,
      canvas, selectedWorkLocationType,
      project, workLocations,
      projectFloors, projectId,
      workSteps, workStepColors,

      // methods
      isMobileWebApp, isNativeApp,
      openLoginModal,
      formatDate, getRelativeDate,
      getProxyImgLink,
      checkProjectUserPermission,
      t, tStr, getLocalizedStr,
      setPopoverOpen,
      renderOLMap,
      tmpGetPhotoLink,
      navigateMaterialCategories: (direction) => {
        const selectedSegmentBtn = document.querySelector(`.sections ion-segment-button.segment-button-checked`);
        const targetElement: any = direction == 'prev' ? selectedSegmentBtn.previousElementSibling : selectedSegmentBtn.nextElementSibling;
        if (targetElement) {
          targetElement.click();
          targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
          });
        }
      },

      // Work Locations
      getWorkLocationNameById,
      existWorkLocations: (type) => (project.value.workLocations?.filter(wl => wl['類別'] == type).length > 0),
      onWorkLocationIdChanged: async (newWorkLocation = undefined) => {
        if (newWorkLocation !== undefined) {
          selectedWorkLocationType.value = newWorkLocation['類別'];
          await sleep(0.1);
          canvas.workLocationId = newWorkLocation.id;
        }
        if (canvas.workLocationId == 'overview') {
          canvas.loadingMapPhotos = true;
          // overview dashboard (export images of all maps and present them on screen)
          //const loading = await loadingController.create({});
          //await loading.present();
          const filteredFloorPlans = workLocations.value.filter(p => p.floorPlanPhotoLink);
          for (const wl of filteredFloorPlans) {
            const { id: planId, imgHeight, imgWidth, } = wl;
            const { extentWidth, extentHeight, mapCanvasDataURL } = await getMapCanvasDataURL(planId, imgHeight, imgWidth);
            wl.outputMapPhotoLink = mapCanvasDataURL;
          }
          //loading.dismiss();
          canvas.loadingMapPhotos = false;
        } else {
          renderOLMap(null);
        }
      },
      onClickWorkLocationMapImg: (workLocationId) => {
        canvas.prevIsOverview = true;
        canvas.workLocationId = workLocationId;
        renderOLMap(null);
      },

      // Form input
      handleFloorChange,
      handleSubLocationChange,

      // Visible steps for selected point
      getWorkPhotoRecordVisibleSteps,

      // Add / update / view photo
      openNormalImageModal: async (imageLink, caption = "") => {
        return await openModal(ImageModal, { imageLink, caption, }, true, 'image-modal');
      },
      openImageModal: async (selectedPoint: any, step: any) => {
        const canEdit = checkProjectUserPermission('canEditOLPage');
        const imageLink = tmpGetPhotoLink(selectedPoint[`${step}相片`], true);
        const caption = `${selectedPoint['照片編號']}`;
        await openModal(ImageModal, { imageLink, caption, checkLocationId: selectedPoint.id, step, projectId, canEdit, }, true, 'image-modal');
      },
      takePhotoForStep: async (id, step) => {
        const photo: Photo = await takePhoto(2000, 80, isMobileWebApp() || isNativeApp()); // show prompt only on mobile
        if (photo) {
          const loading = await loadingController.create({});
          await loading.present();
          const newPhotoLink = await ProjectService.updateCheckLocationImage(projectId, canvas.selectedPointId, step, photo);
          console.log(newPhotoLink);
          store.commit('upsertCheckLocation', { id, [`${step}相片`]: newPhotoLink, projectId });
          canvas.selectedPoint[`${step}相片`] = newPhotoLink;
          presentToast(t('successUpdatePhoto'), 1000, 'bottom');
          loading.dismiss();
        }
      },

      // Point operations
      unselectPoint: () => {
        canvas.selectedFeature.setStyle(null);
        setSelectedPoint(null, {}, null); // unselect active point
      },
      deletePoint: (pointId) => {
        presentPrompt("", t('confirmDelete'), () => {
          const feature: any = source.getFeatureById(pointId);
          if (feature) {
            clearPointOnCanvas(pointId);
            if (feature.get('type') == 'point') {
              ProjectService.deleteCheckLocation(pointId);
              store.commit('deleteCheckLocation', { projectId, id: pointId });
            }
            else if (getPointTypeObj(feature.get('type'))['table'] == 'assets') {
              AssetService.deleteAssetPoint(pointId);
            }
            else {
              DeviceService.deleteDevicePoint(pointId);
            }
          }
          setSelectedPoint(null, {}, null);
        });
      },

      closePointModal,
      upsertPointEntity: async () => {
        const loading = await loadingController.create({});
        await loading.present();

        // Upsert point info to DB
        const [pointX, pointY] = canvas.targetCoordinates;
        const { pointTargetEntityType, deviceId, deviceMinor, isAttendanceDevice, workerName, simPhone, selectedPointId: id, workLocationId, targetFloor,
                photoCode, seqNumber, damageType, damageAreaLength, damageAreaWidth, ironState, subLocationId, } = canvas;

        if (pointTargetEntityType == 'workPhotoRecord') {
          // New Check Location
          const payload = { projectId, id, pointX, pointY, workLocationId, targetFloor, photoCode, seqNumber, damageType, damageAreaLength, damageAreaWidth, ironState, subLocationId, };
          const res = await ProjectService.upsertCheckLocation(payload);
          store.commit('upsertCheckLocation', { ...res, projectId });

          // Show new point on map & select it
          if (!canvas.selectedPointId) {
            const pointObj = getWorkPhotoRecordById(res.id);
            const feature = addPoint(res.id, canvas.targetCoordinates, pointObj);
            setSelectedPoint(res.id, res, feature);
            clearDummyFeature(false); // clear dummy point
          }
        }
        else {
          const pointTypeObj = getPointTypeObj(pointTargetEntityType);

          if (!canvas.selectedPointId) {
            clearDummyFeature(false); // clear dummy point
            setSelectedPoint(null, {}, null); // unselect active point
          }
          if (pointTypeObj['id'] == 'batch-anchor') {
            // Batch create wall anchors on map based on input params
            const { currWorkLocation, diffXPerMeter, horizontalSpacing, diffMetersBetweenFloors, } = canvas;
            const { imgWidth, imgHeight } = currWorkLocation;
            const payload = { id, pointX, pointY, workLocationId, targetFloor, projectId,
                              imgWidth, imgHeight, diffXPerMeter, horizontalSpacing, diffMetersBetweenFloors, };
            const res = await AssetService.batchCreateNewAnchorAssets(payload);
            store.commit('upsertAssets', res);
            for (const newAsset of res) {
              addPoint(newAsset.id, [Number(newAsset.pointX), Number(newAsset.pointY)], null, 'anchor', null, newAsset);
            }
          }
          else if (pointTypeObj['table'] == 'assets') {
            // New / updated assets (wall anchors)
            const payload = { id, pointX, pointY, workLocationId, targetFloor, pointTargetEntityType, projectId, };
            const res = (id ? await AssetService.updateAsset(payload) : await AssetService.createNewAsset(payload));
            store.commit('upsertAssets', [res]);
            addPoint(res.id, [Number(pointX), Number(pointY)], null, pointTargetEntityType, null, res);
          }
          else {
            // New / updated Device
            const targetDeviceId = deviceId || `${pointTargetEntityType}-${uniqueId()}`;
            canvas.deviceId = targetDeviceId; // assign random ID if empty
            const payload = { id: targetDeviceId, deviceMinor, isAttendanceDevice, workerName, simPhone, pointX, pointY,
                              workLocationId, targetFloor, subLocationId, pointTargetEntityType, projectId, };
            const res = await DeviceService.upsertDevice(payload);
            store.commit('upsertDevice', res);
            addPoint(targetDeviceId, [Number(pointX), Number(pointY)], null, pointTargetEntityType, res);
          }
        }

        // reset point info & close modal
        loading.dismiss();
        setCanvasPointInfo();
        canvas.isPointModalOpened = false;
      },
      openEditPointModal: () => {
        const selectedPoint: any = canvas.selectedPoint;
        const entityType = canvas.selectedFeature.get('type');
        if (entityType == 'point') {
          // Work Photo Record (point)
          const { "樓層": targetFloor, "照片編號": photoCode, seqNumber, "破損類型": damageType, "破損面積長": damageAreaLength, "破損面積闊": damageAreaWidth, "鐵狀態": ironState, subLocationId, } = selectedPoint;
          setCanvasPointInfo(targetFloor, photoCode, seqNumber, damageType, damageAreaLength, damageAreaWidth, ironState, subLocationId);
        } else {
          // Asset / Device
          const { id, floor, subLocationId, type, minor, workerName, isTrackingAttendance, iccid, } = selectedPoint;
          setCanvasPointInfo(floor, "", "", "", "", "", "", subLocationId, type, id, minor, workerName, isTrackingAttendance, iccid);
        }
        canvas.isPointModalOpened = true;
      },

      // Export to PDF (外牆圖 / 內牆圖)
      exportFloorPlansToPDF: async () => {
        const pdf = new jsPDF().deletePage(1); // empty PDF

        const loading = await loadingController.create({});
        await loading.present();

        const filteredFloorPlans = workLocations.value.filter(p => p.floorPlanPhotoLink);
        for (let i = 0; i < filteredFloorPlans.length; i++) {
          // Generate map images
          const { id: planId, imgHeight, imgWidth, orientation, } = filteredFloorPlans[i];
          const { extentWidth, extentHeight, mapCanvasDataURL } = await getMapCanvasDataURL(planId, imgHeight, imgWidth);

          // Add canvas content to PDF
          pdf.addPage([extentWidth, extentHeight], orientation || 'portrait'); // add page that fits the specified orientation
          pdf.addImage(mapCanvasDataURL, 'JPEG', 0, 0, extentWidth, extentHeight);
        }
        if (isNativeApp()) {
          const fileName = `${new Date().valueOf()}.pdf`;
          const res = await Filesystem.writeFile({
            path: fileName,
            //data: pdf.output('datauristring', { filename: fileName }), //  data:application/pdf;filename=外牆圖.pdf;base64,JVBERi0xLjMKJbrfrOAKMyA...
            data: pdf.output('datauristring', { filename: fileName }).split(',').pop(),
            directory: Directory.Documents, // or Directory.ExternalStorage for external storage
            //directory: Directory.Cache,
            //encoding: Encoding.UTF8,
          });
          loading.dismiss();
          await FileOpener.open({ filePath: res.uri }); // open the file
          //await Share.share({ url: res.uri }); // share the file
        } else {
          const fileName = `${selectedWorkLocationType.value}圖.pdf`;
          pdf.save(fileName);
          loading.dismiss();
        }

        renderOLMap(); // reset to original map
      },

      // Export to PDF photo report
      exportWorkLocationsToFullReport: async () => {
        const loading = await loadingController.create({});
        await loading.present();
        const setLoadingText = async (text, wl) => {
          loading.message = wl ? `${wl['位置']} - ${text}` : text;
          await sleep(0.1);
        }

        const doc = new jsPDF({ format: 'a4' });
        doc.setFont('TaipeiSansTCBeta-Regular', 'normal'); // Set global font
        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.getWidth(), pageHeight = pageSize.getHeight();
        const totalPagesExp = '{total_pages_count_string}';
        const buildingName = project.value.title;
        
        // Header / footer images
        const headerImg: any = await getHTMLImg(require('@/assets/header.jpg'));
        const footerImg: any = await getHTMLImg(require('@/assets/footer_address.jpg'));
        const footerLogos: any = await getHTMLImg(require('@/assets/footer_logos.jpg'));

        // Helper functions (header / footer / section page)
        const addHeader = () => {
          const width = 80, height = width / headerImg.aspectRatio;
          doc.addImage(headerImg.img, 'JPEG', 20, 5, width, height);
        }
        const addFooter = () => {
          // Footer
          let str = 'Page ' + (doc as any).internal.getNumberOfPages();
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp // Total page number plugin only available in jspdf v1.0+
          }
          doc.setFontSize(8);
          doc.text(str, pageWidth-20, pageHeight-5);

          // Address image
          let width = 150, height = width / footerImg.aspectRatio;
          doc.addImage(footerImg.img, 'JPEG', (pageWidth-width)/2, pageHeight-height, width, height);

          // Logos
          width = 30, height = width / footerLogos.aspectRatio;
          doc.addImage(footerLogos.img, 'JPEG', pageWidth-width-5, pageHeight-height-20, width, height);
        }
        const addSectionPage = (sectionTitle, addNewPage = true) => {
          if (addNewPage) doc.addPage('a4', 'p');
          addHeader(); addFooter();
          const fontSize = 36;
          doc.setFontSize(fontSize);
          doc.text(sectionTitle, pageWidth/2, pageHeight/2, { align: 'center' });
        }

        /**
         * Report Cover Page
         */
        const label = selectedWorkLocationType.value == '外牆' ? `外內結構修葺` : `室內結構修葺`;
        const reportTitle = `${buildingName}\n${label}\n相片記錄`;
        addSectionPage(reportTitle, false);

        const getRelatedCLs = (workLocation) => {
          const checkKey = selectedWorkLocationType.value == '內牆' ? 'subLocationId' : '勘察位置';
          return project.value.workPhotoRecords.filter(l => l[checkKey] == workLocation.id).sort((a, b) => {
            const floorA = a['樓層'] || "", floorB = b['樓層'] || "";
            if (floorA == floorB) return floorA['照片編號'] < floorB['照片編號'] ? -1 : 1;
            if (floorA == 'R/F') return -1; // put to top
            if (floorA == 'G/F') return 1; // put to bottom
            return Number(floorB.split("/")[0]) - Number(floorA.split("/")[0]);
          });
        }

        /**
         * Summary tables & statistics
         */
        addSectionPage(`修葺批盪及\n結構數量`);
        const subLocations = workLocations.value.filter(p => !p.floorPlanPhotoLink && p['多個樓層?'] == true);
        const relatedWorkLocations = (subLocations.length > 0 ? subLocations : workLocations.value);
        for (const wl of relatedWorkLocations) {
          const relatedWorkPhotoRecords = getRelatedCLs(wl);
          if (relatedWorkPhotoRecords.length == 0) continue; // skip if no check locations (points)

          await setLoadingText('產生修葺批盪及結構數量表格中...', wl);

          // Table rows
          const body = [], totalAreas = {};
          for (let i = 0; i < relatedWorkPhotoRecords.length; i++) {
            const cl = relatedWorkPhotoRecords[i], idx = i+1;
            const { "樓層": floor, "照片編號": photoCode, "破損類型": damageType, "破損位置": damageLocation,
                    "破損面積長": damageAreaLength, "破損面積闊": damageAreaWidth, "鐵狀態": ironState } = cl;
            const damageDimension = `${(damageAreaLength/1000).toFixed(1)}m x ${(damageAreaWidth/1000).toFixed(1)}m`;
            const damageArea = ((damageAreaWidth*damageAreaLength)/1000/1000).toFixed(2);
            totalAreas[damageType] = totalAreas[damageType] || 0;
            totalAreas[damageType] += parseFloat(damageArea);
            body.push({
              idx: idx,
              photoCode,
              location: wl['類別'], // 外牆 / 內牆
              floor,
              locationIdx: idx,
              damageLocation,
              damageType,
              damageDimension,
              damageArea: `${damageArea} m²`,
              remarks: '',
            });
          }
          // Add total area rows
          body.push({ damageDimension: '結構破損', damageArea: `${(totalAreas['結構破損'] || 0).toFixed(2)} m²` })
          body.push({ damageDimension: '批盪破損', damageArea: `${(totalAreas['批盪破損'] || 0).toFixed(2)} m²` })

          // Build the table
          doc.addPage('a4', 'p');
          doc.setFontSize(10);
          const loc = (selectedWorkLocationType.value == '外牆' ? `${wl.photoCodePrefix} ${wl['類別']}` : `${wl['位置']}`);
          doc.text(`${buildingName} ${loc}打鑿Check List`, 20, 48); // table description
          autoTable(doc, {
            head: [{ idx: 'No.', photoCode: '照片編號', location: '勘察位置', floor: '樓層', locationIdx: '位置編號',
                    damageLocation: '破損位置', damageType: '破損類型', damageDimension: '破損面積', damageArea: '破損總面積', remarks: '備註' }],
            body,
            styles: { cellPadding: 1, fontSize: 6, font: 'TaipeiSansTCBeta-Regular' },
            headStyles: { cellPadding: 1, fontSize: 6, font: 'TaipeiSansTCBeta-Regular' },
            willDrawPage: (data) => {
              addHeader();
            },
            didDrawPage: (data) => {
              addFooter();
            },
            didParseCell: (data: any) => {
              const { damageType, damageDimension, } = data.row.raw || {};
              if (damageType == '結構破損' || (damageDimension == '結構破損' && data.cell.raw)) {
                data.cell.styles.fillColor = [255, 255, 0];
              }
            },
            margin: {
              top: 50, bottom: 30, left: 20, right: 20,
            },
          });
        }

        /**
         * Then show photos step by step
         */
        for (const wl of relatedWorkLocations) {
          const relatedWorkPhotoRecords = getRelatedCLs(wl);
          if (relatedWorkPhotoRecords.length == 0) continue; // skip if no check locations (points)

          // Step section
          for (const step of workSteps) {
            if (['定位'].includes(step)) continue; // skip steps like '定位'

            await setLoadingText(`下載${step}相片中...`, wl);

            // Table settings
            const margin = { top: 40, bottom: 30, left: 20, right: 20, };
            const usableWidth = pageWidth - margin.left - margin.right;
            const usableHeight = pageHeight - margin.top - margin.bottom;
            const captionHeight = 10; // Height for the caption text, adjust accordingly
            const spaceBetweenImages = 10; // Space between the image and the caption

            // Calculate a fixed height for the images, accounting for captions and spacing
            const fixedImageHeight = (usableHeight / 3) - spaceBetweenImages;

            // Set up body rows
            const imageRows = [], captionRows = [];

            // Download images
            await Promise.all(relatedWorkPhotoRecords.map(async (cl) => {
              const visibleSteps = getWorkPhotoRecordVisibleSteps(cl).visibleSteps.map(s => s.text);
              if (visibleSteps.includes(step)) {
                const photoKey = `${step}相片`;
                cl.imgBase64 = '欠相';
                if (cl[photoKey]) {
                  const relatedPhotoLink = tmpGetPhotoLink(cl[photoKey], true, 600).replace('/public', '/report');
                  //console.log(relatedPhotoLink);
                  cl.imgBase64 = await getBase64FromUrl(relatedPhotoLink);
                }
              }
            }));

            // Add images & caption to table
            for (let idx = 0; idx < relatedWorkPhotoRecords.length; idx++) {
              const cl = relatedWorkPhotoRecords[idx];
              const visibleSteps = getWorkPhotoRecordVisibleSteps(cl).visibleSteps.map(s => s.text);
              if (visibleSteps.includes(step)) {
                imageRows.push({ content: cl.imgBase64, styles: { fontSize: 36, valign: 'middle', halign: 'center', minCellHeight: fixedImageHeight, } });

                const caption = `照片編號：${cl['照片編號']}  No.${idx+1}.\n位置：${wl['位置']} 樓層：${cl['樓層']} 位置${idx+1}`;
                captionRows.push({ content: caption, styles: { valign: 'middle', halign: 'center', minCellHeight: captionHeight } });
              }
            }

            // Exist check locations for specific step
            if (imageRows.length > 0) {
              // Build body rows
              const bodyRows = [];
              for (let i = 0; i < imageRows.length; i += 2) {
                const subImageRows = imageRows.slice(i, i+2);
                const subCaptionRows = captionRows.slice(i, i+2);
                if (subImageRows.length == 1) {
                  subImageRows.push({ content: '', styles: { minCellHeight: fixedImageHeight, } })
                  subCaptionRows.push({ content: '', styles: { minCellHeight: captionHeight, } })
                }
                bodyRows.push(subImageRows);
                bodyRows.push(subCaptionRows);
              }

              // Add section cover page
              addSectionPage(`${step}工序`);
              await setLoadingText(`產生${step}相片表格中...`, wl);

              // Table with photos of the step
              doc.addPage('a4', 'p');
              doc.setFontSize(10);
              autoTable(doc, {
                body: bodyRows,
                didParseCell: (data: any) => {
                  const content = data.cell.raw?.content;
                  if (content && typeof content === 'string' && content.startsWith('data:image')) {
                    data.cell.text = ''; // Clear any text
                  }
                },
                didDrawCell: (data: any) => {
                  // Draw the image if this is an image cell
                  const content = data.cell.raw?.content;
                  if (content && typeof content === 'string' && content.startsWith('data:image')) {
                    const imgProps = doc.getImageProperties(content);
                    // Scale the image to fit the fixed height while maintaining aspect ratio
                    const scaleFactor = fixedImageHeight / imgProps.height;
                    const imgWidth = imgProps.width * scaleFactor;
                    
                    // Calculate x position to center the image in the cell if it's narrower than half the page
                    const imgX = imgWidth < usableWidth / 2 ? data.cell.x + (usableWidth / 2 - imgWidth) / 2 : data.cell.x;
                    const imgY = data.cell.y;

                    // Draw the image
                    doc.addImage(content, 'JPEG', imgX, imgY, imgWidth, fixedImageHeight);
                  }
                },
                willDrawPage: (data) => {
                  addHeader();
                  doc.setFontSize(10);
                  doc.text(`工程位置：${project.value.address} 『${buildingName}』`, 20, 35); // table description
                  doc.text(`報告內容：${wl['位置']}${step}相片紀錄`, pageWidth-70, 35); // table description
                },
                didDrawPage: (data) => {
                  addFooter();
                },
                styles: { cellPadding: 1, fontSize: 6, font: 'TaipeiSansTCBeta-Regular', lineWidth: 0.5, lineColor: [0, 0, 0] },
                headStyles: { cellPadding: 1, fontSize: 6, font: 'TaipeiSansTCBeta-Regular', lineWidth: 0.5, lineColor: [0, 0, 0] },
                margin,
              });
            }
          }
        }

        loading.dismiss();
        doc.putTotalPages(totalPagesExp); // put total page data on pages

        if (isNativeApp()) {
          const fileName = `${new Date().valueOf()}.pdf`;
          const res = await Filesystem.writeFile({
            path: fileName,
            data: doc.output('datauristring', { filename: fileName }).split(',').pop(),
            directory: Directory.Documents, // or Directory.ExternalStorage for external storage
          });
          await FileOpener.open({ filePath: res.uri }); // open the file
          loading.dismiss();
        } else {
          const fileName = `${reportTitle.replace(/\n/g, "")}.pdf`;
          doc.save(fileName); // download the document
          loading.dismiss();
        }
        return doc;
      },

      // Searchbar
      searchKeyword,
      onClickSearchBtn: () => {
        canvas.isSearching = true;
        focusKeywordSearchbar();
      },

      // View settings
      toggleVisibility: (infoKey, isChecked) => {
        //canvas.showCurrStepPhotos = !canvas.showCurrStepPhotos;
        canvas[infoKey] = isChecked;
        source.changed();

        // sync to DB
        const appPreferences = currUser.value.appPreferences;
        appPreferences[infoKey] = isChecked;
        const updatedUser = { appPreferences };
        UserService.updateLoggedInUser(updatedUser);
        //store.commit('updateUser', updatedUser);
      },
      
      // QR Code
      scanningQRCode, startScanQRCode, stopScan,
      startScanDeviceQRCode: async () => {
        const deviceId: any = await startScanQRCode();
        if (deviceId != null) canvas.deviceId = deviceId;
      },

      // Statistics
      getTotalRepairArea: (targetWorkPhotoRecords: any = null) => {
        const relatedWorkPhotoRecords = targetWorkPhotoRecords || project.value.workPhotoRecords?.filter(l => {
          if (canvas.workLocationId == 'overview') return workLocations.value.find(wl => wl.id == l['勘察位置']); // 勘察位置 of the same type
          return l['勘察位置'] == canvas.workLocationId;
        });
        return (relatedWorkPhotoRecords || []).reduce((total, curr) => {
          return total + (curr['破損面積長'] ? (curr['破損面積長']*curr['破損面積闊'])/1000/1000 : 0)
        }, 0).toFixed(2);
      },
      getWorkPhotoRecordStatObj: (step, countAllPoints = false, targetWorkPhotoRecords: any = null) => {
        let totalPointsWithPhotos = 0, totalPoints = 0;
        const relatedWorkPhotoRecords = targetWorkPhotoRecords || project.value.workPhotoRecords?.filter(l => {
          if (countAllPoints) return true; // for summary statistics
          if (canvas.workLocationId == 'overview') return workLocations.value.find(wl => wl.id == l['勘察位置']); // 勘察位置 of the same type
          return l['勘察位置'] == canvas.workLocationId;
        });
        for (const record of relatedWorkPhotoRecords || []) {
          const { visibleSteps, missingPhotoSteps, } = getWorkPhotoRecordVisibleSteps(record);
          if (visibleSteps.find(s => s.text == step)) {
            totalPoints++;
            if (!missingPhotoSteps.includes(step)) totalPointsWithPhotos++;
          }
        }
        let progress = totalPointsWithPhotos/totalPoints;
        let summary = `${parseFloat((progress*100).toFixed(1))}% (${totalPointsWithPhotos}/${totalPoints} 已完成) ${progress >= 1 ? '✅' : ''}`;
        if (totalPoints == 0) {
          progress = 0;
          summary = `未有數據`;
        }
        return { totalPointsWithPhotos, totalPoints, progress, summary,  };
      },
      currVisibleWorkPhotoRecords: () => {
        const currKeyword = searchKeyword.value.toLowerCase();
        let filteredRecords = getWorkPhotoRecordsByType(selectedWorkLocationType.value, currKeyword);
        if (canvas.workLocationId != 'overview') filteredRecords = filteredRecords.filter(l => (l['勘察位置'] == canvas.workLocationId));
        return filteredRecords.sort((a, b) => toNum(a['照片編號']) - toNum(b['照片編號']));
        //return (project.value.workPhotoRecords || []).filter(l => (l['勘察位置'] == canvas.workLocationId && l['照片編號']?.toLowerCase().includes(currKeyword)))
      },
      filteredWorkPhotoRecords: () => {
        const keyword = searchKeyword.value.toLowerCase();
        return !keyword ? [] : (project.value.workPhotoRecords || []).filter(l => (l['照片編號']?.toLowerCase().includes(keyword)))
                                                                      .sort((a, b) => toNum(a['照片編號']) - toNum(b['照片編號']));
      },
      selectPointOnMap: async (point: any) => {
        if (canvas.workLocationId != point['勘察位置']) {
          const workLocation = project.value.workLocations?.find(wl => wl.id == point['勘察位置']) || {};
          selectedWorkLocationType.value = workLocation['類別'];
          await sleep(0.5); // wait for type switch
          canvas.workLocationId = point['勘察位置'];
          preSelectWorkPhotoRecordId = point.id;
          renderOLMap(canvas.workLocationId, false, false);
        } else {
          const relatedFeatures = source.getFeatures().filter(f => f.get('groupId') == point.id);
          const targetFeature = relatedFeatures.find(f => f.getId() != null);
          if (targetFeature) setSelectedPoint(point.id, point, targetFeature, targetFeature); // select active point
        }
      },
      getCurrFooterType: () => {
        if (canvas.isPointModalOpened) return 'point-form';
        if (canvas.linkingDeviceAssetId) return 'link-device-form'; 
        if (canvas.selectedPointId) return 'point-details';
        if (!scanningQRCode.value) return 'summary';
      },

      // Devices (cam / helmets / ...)
      pointEntityTypes,
      todayDeviceAlerts,
      getTodayDeviceAlertsByDeviceId: (deviceId) => (todayDeviceAlerts.value.filter(a => a.deviceId == deviceId)),
      todayNumNoHelmet: (selectedDeviceOnly = false) => {
        const res = todayDeviceAlerts.value.filter(a => a.event.includes('無安全帽'));
        return selectedDeviceOnly ? res.filter(a => a.deviceId == canvas.selectedFeature.getId()).length : res.length;
      },
      todayNumNoVest: (selectedDeviceOnly = false) => {
        const res = todayDeviceAlerts.value.filter(a => a.event.includes('無反光衣'));
        return selectedDeviceOnly ? res.filter(a => a.deviceId == canvas.selectedFeature.getId()).length : res.length;
      },
      getHelmetBatteryIcon: () => {
        const { batteryLevel, isCharging } = selectedDevice('helmet');
        if (isCharging == true) return batteryCharging;
        if (batteryLevel == 1) return batteryFull;
        if (batteryLevel == 0) return batteryDead;
        return batteryHalf;
      },
      numOfDevicesByType: (type, countOnlineOnly = false, helmetPosture = null) => {
        return (project.value.devices || []).filter(d => {
          return d.type == type && (!countOnlineOnly || isDeviceOnline(d)) && (helmetPosture == null || d.helmet.posture == helmetPosture)
        }).length;
      },
      checkSelectedFeatureType: (type) => (canvas.selectedFeature.get('type') == type),
      selectedDevice, selectedAsset, selectedAssetPart,
      isDeviceOnline, latestDeviceVal,
      openLinkDeviceModal: (childAssetType) => {
        const parentAsset = selectedAsset(), childAsset = selectedAssetPart(childAssetType);
        canvas.linkingDeviceAssetId = childAsset.id;
        canvas.linkingDeviceAssetLabel = `${parentAsset.floor} ${getLocalizedStr(pointEntityTypes.find(t => t.id == parentAsset.type), 'name', 'nameEn')} - ${childAssetType}`;
      },

      // Assets
      latestParentAssetData, latestAssetPartVal, formatVal,
      linkDeviceToAsset: async () => {
        const loading = await loadingController.create({});
        await loading.present();
        const { linkingDeviceAssetId, deviceId, selectedPointId, } = canvas;
        const res = await AssetService.linkChildAssetToDevice(linkingDeviceAssetId, deviceId, selectedPointId, projectId);
        store.commit('upsertAssets', [res]);
        loading.dismiss();
        canvas.linkingDeviceAssetId = null; // close the modal
        canvas.linkingDeviceAssetLabel = null;
        canvas.deviceId = null; // reset input device ID
      },
      unlinkDeviceFromChildAsset: async (childAssetType) => {
        presentPrompt("", tStr("確認解除連結監測裝置?", "Confirm unlinking the moniotoring device?"), async () => {
          const loading = await loadingController.create({});
          await loading.present();
          const { id, parentId, linkedDeviceId } = selectedAssetPart(childAssetType);
          const res = await AssetService.linkChildAssetToDevice(id, null, parentId, projectId, linkedDeviceId); // device ID null = unlink
          store.commit('upsertAssets', [res]);
          loading.dismiss();
        });
      },
      getWallAnchorStatus: () => (getAnchorAssetStatus(selectedAsset('parts'))),
      cleanupUnlinkedAssets: async () => {
        presentPrompt("", "Confirm deleting assets not linked to any devices?", async () => {
          const loading = await loadingController.create({});
          await loading.present();

          // Clean up assets that are not linked to any devices
          await AssetService.cleanupUnlinkedAssets(projectId, canvas.workLocationId);

          // Fetch latest project assets & refresh map
          const project = await ProjectService.getProjectById(projectId);
          store.commit('upsertProjects', [project]);
          renderOLMap(canvas.workLocationId, map.getView().getZoom());

          loading.dismiss();
        });
      },
      numOfAssetsByType,
      numOfAnchorsByStatus,

      // Scaffold Report
      getScaffoldStatusByPutlogCount: () => {
        const percentage = (numOfAnchorsByStatus('normal') / numOfAssetsByType('anchor')) * 100;
        if (percentage >= 80) return { code: 'normal', text: tStr(`安全`, 'Safe'), color: 'success' };
        return { code: 'abnormal', text: tStr(`有問題`, 'Abnormal'), color: 'danger' }; // very simple calculation
        //if (percentage >= 80) return { code: 'normal', text: tStr(`連牆器數量合乎規範`, 'Putlog count compliant with standards') };
        //return { code: 'abnormal', text: tStr(`連牆器數量少於標準`, 'Putlog count below standards') }; // very simple calculation
      },
      openScaffoldReportModal: async (asset = null, workLocationId = null) => {
        return await openModal(ScaffoldReportModal, { workLocationId, asset, project: project.value }, true, 'report-modal');
      },
      getDeviceLogMsg, alertIcons,

      toggleShowBackgroundImg: (isChecked) => {
        canvas.showBackgroundImg = isChecked;

        if (isChecked) document.body.classList.add('tech-bg');
        else document.body.classList.remove('tech-bg');

        // sync to DB
        const appPreferences = currUser.value.appPreferences;
        appPreferences.showBackgroundImg = isChecked;
        const updatedUser = { appPreferences };
        UserService.updateLoggedInUser(updatedUser);
      },

      // Left Pane
      PROJECT_STATUSES, getProjectStatusColor, getProjectDisplayProgress,
      numberWithCommas, formatDateString, addResizeUrlParams,
      getRelatedWorkPhotoRecords: (workLocationId) => (project.value.workPhotoRecords.filter(l => l['勘察位置'] == workLocationId)),

      // CRUD Work Locations
      promptWorkLocationName: async (id = null, prefilledName = "", prefilledPrefix = "", prefilledAlias = "") => {
        const upsertWorkLocation = async (name, photoCodePrefix, alias) => {
          const loading = await loadingController.create({});
          await loading.present();
          const newWorkLocation = await WinsService.upsertWorkLocation({
            id, projectId, name, alias, isMultipleFloors: true, type: '內牆', photoCodePrefix,
          });
          store.commit('upsertWorkLocation', newWorkLocation);
          loading.dismiss();
          alertController.dismiss();
        }
        const alert = await alertController.create({
          backdropDismiss: false,
          header: tStr('工程位置', 'Work Location'),
          inputs: [
            {
              name: 'locationName',
              type: 'text',
              value: prefilledName,
              placeholder: tStr('位置名稱', 'Location Name'),
            },
            {
              name: 'photoCodePrefix',
              type: 'text',
              value: prefilledPrefix,
              placeholder: `${tStr('相片編號開首', 'Photo Code Prefix')} (e.g. ST1)`,
            },
            {
              name: 'locationAlias',
              type: 'text',
              value: prefilledAlias,
              placeholder: `${tStr('代號', 'Alias')} (e.g. 前梯)`,
            },
          ],
          buttons: [
            {
              text: t('cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: t('confirm'),
              handler: (value) => {
                if (value.locationName) {
                  upsertWorkLocation(value.locationName, value.photoCodePrefix, value.locationAlias);
                }
                return false; // not closing the alert
              },
            },
          ],
        });
        await alert.present();
      },
      deleteWorkLocation: (workLocationId) => {
        presentPrompt("", t('confirmDelete'), async () => {
          const loading = await loadingController.create({});
          await loading.present();
          await WinsService.deleteWorkLocation(workLocationId);
          store.commit('deleteWorkLocation', { projectId, id: workLocationId });
          if (canvas.workLocationId == workLocationId) {
            canvas.workLocationId = workLocations.value[0]?.id;
            renderOLMap();
          }
          loading.dismiss();
        });
      },

      promptFloorPlanDetails: async (type, id = null, prefilledAlias = "", prefilledPrefix = "") => {
        const upsertWorkLocation = async (alias, photoCodePrefix) => {
          const loading = await loadingController.create({});
          await loading.present();
          const newWorkLocation = await WinsService.upsertWorkLocation({
            id, projectId, type, alias, name: `${type}${alias}`,
            targetFloor: (type == '內牆' ? alias : ''),
            isMultipleFloors: (type == '外牆'),
            photoCodePrefix,
          });
          store.commit('upsertWorkLocation', newWorkLocation);
          loading.dismiss();
          alertController.dismiss();
        }
        const alert = await alertController.create({
          backdropDismiss: false,
          header: tStr('平面圖資料', 'Floor Plan Info'),
          inputs: [
            {
              name: 'locationAlias',
              type: 'text',
              value: prefilledAlias,
              placeholder: type == '外牆' ? `${tStr('代號', 'Alias')} (e.g. A面)` : `${tStr('樓層', 'Floor')} (e.g. 15/F)`,
            },
            {
              name: 'photoCodePrefix',
              type: 'text',
              value: prefilledPrefix,
              placeholder: `${tStr('相片編號開首', 'Photo Code Prefix')} (e.g. B)`,
            }
          ],
          buttons: [
            {
              text: t('cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: t('confirm'),
              handler: (value) => {
                if (value.locationAlias) {
                  upsertWorkLocation(value.locationAlias, value.photoCodePrefix);
                }
                return false; // not closing the alert
              },
            },
          ],
        });
        await alert.present();
      },
      uploadWorkLocationFloorPlan: async () => {
        const id = canvas.workLocationId;
        const photo: Photo = await takePhoto();
        if (photo) {
          const loading = await loadingController.create({});
          await loading.present();
          const imgStatObj: any = await getHTMLImg(photo.base64Data);
          const { width: imgWidth, height: imgHeight } = imgStatObj;
          const orientation = imgWidth > imgHeight ? 'landscape' : 'portrait';
          const floorPlanPhotoLink = await WinsService.updateWorkLocationFloorPlan(id, photo, imgWidth, imgHeight, orientation);
          store.commit('upsertWorkLocation', { id, floorPlanPhotoLink, projectId, imgWidth, imgHeight, orientation, });
          canvas.currWorkLocationFloorPlanPhotoLink = floorPlanPhotoLink;
          loading.dismiss();
          renderOLMap(); // re-render map
        }
      },

      // CRUD project_users
      projectRoles,
      currProjectUser,
      openProjectUserModal: async (oldProjectUser: any = {}) => {
        currProjectUser.id = oldProjectUser.id || "";
        currProjectUser.firstName = oldProjectUser.user?.firstName || "";
        currProjectUser.phone = oldProjectUser.user?.phone || "";
        currProjectUser.roleId = oldProjectUser.role?.id || "";
        canvas.isProjectUserModalOpened = true;
      },
      upsertProjectUser: async () => {
        const loading = await loadingController.create({});
        await loading.present();
        await WinsService.upsertProjectUser({ ...currProjectUser, projectId });
        store.dispatch('fetchProjectDetails', { id: projectId }); // fetch product details
        loading.dismiss();
        canvas.isProjectUserModalOpened = false;
      },
      deleteProjectUser: async (id) => {
        presentPrompt("", t('confirmDelete'), async () => {
          const loading = await loadingController.create({});
          await loading.present();
          await WinsService.deleteProjectUser(id);
          store.dispatch('fetchProjectDetails', { id: projectId }); // fetch product details
          loading.dismiss();
          canvas.isProjectUserModalOpened = false;
        });
      },

      // Work Photo Gallery Modal
      openWorkStepGalleryModal: (title, locationId) => {
        canvas.wsgTitle = title; // Location
        canvas.wsgWorkPhotoRecords = project.value.workPhotoRecords.filter(l => l.subLocationId == locationId || l['勘察位置'] == locationId)
                                                                    .sort((a, b) => toNum(a['照片編號']) - toNum(b['照片編號']));
        if (canvas.wsgWorkPhotoRecords.length > 0) {
          canvas.wsgVisbleSteps = workSteps.filter(step => {
            return canvas.wsgWorkPhotoRecords.some(cl => getWorkPhotoRecordVisibleSteps(cl).visibleSteps.map(s=> s.text).includes(step));
          }); // only show related steps
          canvas.isWorkStepGalleryModalOpened = true;
          
        }
      },

      // Uppy
      uppy,
      getUppyInstance: (workPhotoRecord, step) => {
        if (!workPhotoRecord[`uppy-${step}`]) {
          const uppy = new Uppy({
            id: `${workPhotoRecord.id}-${step}`,
            autoProceed: true,
            restrictions: { maxNumberOfFiles: 1, allowedFileTypes: ['image/*'] },
          }).use(Compressor, {
            maxWidth: 2000,
            quality: 0.6,
          }).use(XHR, {
            endpoint: 'https://batch.imagedelivery.net/images/v1',
            allowedMetaFields: [],
            async onBeforeRequest(xhr) {
              const token = await WinsService.fetchImageUploadBatchToken();
              xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            },
            async onAfterResponse(xhr) {
              console.log(JSON.parse(xhr.responseText));
              const result = JSON.parse(xhr.responseText);
              const { variants } = result.result;
              const photoLink = variants.find(v => v.includes("/public")) || variants[0]; // URL of the uploaded image
              const newPhotoLink = await ProjectService.updateCheckLocationImage(projectId, workPhotoRecord.id, step, null, photoLink);
              console.log(newPhotoLink);
              store.commit('upsertCheckLocation', { id: workPhotoRecord.id, [`${step}相片`]: newPhotoLink, projectId });
              workPhotoRecord[`${step}相片`] = newPhotoLink;
              presentToast(t('successUpdatePhoto'), 1000, 'bottom');
            },
          });
          workPhotoRecord[`uppy-${step}`] = uppy;
        }
        return workPhotoRecord[`uppy-${step}`];
      },

      // Batch import
      getWorkPhotoRecordsByType,
      openEditWorkRecordModal: (batchUploadJobFileRow) => {
        canvas.isEditWorkRecordModalOpened = true;
        canvas.bupTargetJobFile = batchUploadJobFileRow;
      },
      updateLinkedWorkPhotoRecord: async (batchUploadJobFileRow, recordId) => {
        const loading = await loadingController.create({});
        await loading.present();
        const res = await WinsService.updateBatchUploadJobFile(batchUploadJobFileRow.id, recordId);
        console.log(res);
        batchUploadJobFileRow.linkedWorkPhotoRecordId = recordId;
        canvas.isEditWorkRecordModalOpened = false;
        loading.dismiss();
      },
      getLinkedWorkPhotoRecord: (batchUploadJobFileRow) => {
        const { relatedPhotoRecord, linkedWorkPhotoRecordId } = batchUploadJobFileRow;
        if (!relatedPhotoRecord || relatedPhotoRecord?.id != linkedWorkPhotoRecordId) {
          batchUploadJobFileRow.relatedPhotoRecord = getWorkPhotoRecordById(linkedWorkPhotoRecordId);
        }
        return batchUploadJobFileRow.relatedPhotoRecord;
      },
      openBatchUploadJobModal: async (batchUploadJob) => {
        const loading = await loadingController.create({});
        await loading.present();
        const res = await WinsService.getBatchUploadJobById(batchUploadJob.id);
        console.log(res);
        canvas.bupUploadedFiles = res.files;
        canvas.bupPrefixLocationMappings = res.mappings;
        canvas.isBatchUploadPhotoModalOpened = true;
        canvas.selectedBatchUploadJob = batchUploadJob;
        canvas.bupRecognitionMode = batchUploadJob.photoCodeRecognitionMode;
        subBatchUploadJobFileTable(batchUploadJob.id);
        loading.dismiss();
      },
      onBeforeOpenBatchUploadPhotoModal: async () => {
        canvas.bupPrefixLocationMappings = [];
        canvas.bupUploadedFiles = [];
      },
      onDismissBatchUploadPhotoModal: async () => {
        canvas.isBatchUploadPhotoModalOpened = false;
        canvas.selectedBatchUploadJob = null;
        if (canvas.bupSupabaseChannel) {
          canvas.bupSupabaseChannel.unsubscribe(); // leave the channel
          canvas.bupSupabaseChannel = null;
        }
      },

      // Scan nearby beacons
      startScanNearbyBeacons: async () => {
        const modal = await modalController.create({
          component: BLEScanModal,
          componentProps: {},
        });
        modal.onDidDismiss().then(({ data }) => {
          if (data && data.deviceId) {
            canvas.deviceId = data.deviceId;
          }
        })
        return modal.present();
      },
    }
  }
}
